import { CallApi } from '../api'
import {
  startGetListUsers,
  successGetListUsers,
  startDeleteUser,
  successDeleteUser,
  failRequestApiFromUserSlice,
} from '../../containers/Users/slice'
import { checkValue } from '../../utils/helper/common'

export const getListUsers = (input?: {
  page: number,
  searchKeyword?: string,
  orderBy?: string,
  typeOrder?: string,
}): ((dispatch: any) => Promise<void>) => {
  return async (dispatch) => {
    let params: Record<string, unknown> = {}
    if (input) {
      const { page, searchKeyword, orderBy, typeOrder } = input
      const data: Record<string, unknown> = {
        page,
        searchKeyword,
        orderBy,
        typeOrder,
      }
      params = checkValue(params, data)
    }
    dispatch(startGetListUsers())
    try {
      const res = await CallApi({
        endpoint: 'user',
        method: 'GET',
        params
      })
      dispatch(successGetListUsers(res))
    } catch (err) {
      dispatch(failRequestApiFromUserSlice(err))
    }
  }
}

export const deleteUser = (id: string): ((dispatch: any) => Promise<void>) => {
  return async (dispatch) => {
    dispatch(startDeleteUser())
    try {
      const res = await CallApi({
        endpoint: `user/${id}`,
        method: 'DELETE',
      })
      dispatch(successDeleteUser(res))
    } catch (err) {
      dispatch(failRequestApiFromUserSlice(err))
    }
  }
}

export const updateEmail = async (data: unknown, callback: (isSuccess: boolean, message?: string) => void): Promise<void> => {
  try {
    const res = await CallApi({
      endpoint: `user/${(data as Record<string, unknown>).id}`,
      method: 'PATCH',
      body: data
    })
    callback(true)
  } catch (err) {
    callback(false, (err as any).message)
  }
}

export const addCoins = async (data: { id: string, value: number }, callback: (isSuccess: boolean, message?: string) => void): Promise<void> => {
  try {
    const res = await CallApi({
      endpoint: `user/addCoins/${data.id}`,
      method: 'POST',
      body: { add: data.value }
    })
    callback(true)
  } catch (err) {
    callback(false, (err as any).message)
  }
}

export const subCoins = async (data: { id: string, value: number }, callback: (isSuccess: boolean, message?: string) => void): Promise<void> => {
  try {
    const res = await CallApi({
      endpoint: `user/subCoins/${data.id}`,
      method: 'POST',
      body: { sub: data.value }
    })
    callback(true)
  } catch (err) {
    callback(false, (err as any).message)
  }
}
