import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ColumnItem } from '../../interfaces/common/table'
import { CustomSearchComponent } from '../../components/elements/CustomSearch'
import { CustomTableContainer } from '../../components/resources/CustomTableContainer'
import { UserInitialState, clearErrorUser } from './slice'
import { UserRow, UserData } from '../../interfaces/user'
import { getListUsers, deleteUser, updateEmail, subCoins, addCoins } from '../../services/rest/user.services'
import { removeCurrentUser, removeToken } from '../../utils/helper/common'
import './index.scss'
import { Button } from '@material-ui/core'
import history from '../../utils/history'
import { CustomMenu } from '../../components/resources/CustomMenu'
import { ConfirmFormModal } from '../../components/resources/ConfirmFormModal'
import { toast, ToastContainer } from 'react-toastify'
import { UpdateUserModal } from '../../components/resources/UpdateUser'
import { UpdateCoinModal } from '../../components/resources/UpdateCoins'
import { getListRequests } from '../../services/rest/request.services'

const columns: ColumnItem[] = [
  { id: 'username', align: 'left', isBlack: true, label: 'User Name' },
  { id: 'email', align: 'left', isBlack: true, label: 'Email' },
  { id: 'id', align: 'left', isBlack: true, label: 'ID' },
  { id: 'referralCode', align: 'left', isBlack: false, label: 'Referral code' },
  { id: 'coins', align: 'left', isBlack: false, label: 'Coins' },
  { id: 'vip', align: 'left', isBlack: false, label: 'VIP' },
  { id: 'createDate', align: 'left', isBlack: false, label: 'Created' },
]

// const listOptionsRoleAdminAndPAT: OptionSelect[] = [
//   { content: 'admin', value: 'ADMIN' },
//   { content: 'patient', value: 'PATIENT' },
// ]

// const listOptionsRoleNUAndEM: OptionSelect[] = [
//   { content: 'nurse', value: 'NURSE' },
//   { content: 'eventManager', value: 'EVENT_MANAGER' },
// ]

interface UserManagementContainerState {
  listUsersTable: UserRow[]
  isOpenUpdatedUserModal: boolean
  isOpenUpdatedCoinModal: boolean
  isOpenReasonForRejectionModal: boolean
  isCreated: boolean
  menuItemSelected: string
  roleFilter: string
  hospitalFilter: string
  orderBy: string
  typeOrder: string
  loading: boolean
  userDetail: UserData
  searchKeyword: string
  isOpenFormConfirm: boolean
  id: string
  totalPages: number
  page: number
}

export const UsersContainer = () => {
  const dispatch = useDispatch()
  const userManagement: UserInitialState = useSelector((state: any) => state.userManagement)
  const { listUsers, error, page, totalPages } = userManagement
  const [state, setState] = useState({
    listUsersTable: [] as UserRow[],
    isOpenUpdatedUserModal: false,
    isOpenUpdatedCoinModal: false,
    isOpenReasonForRejectionModal: false,
    isCreated: false,
    menuItemSelected: '',
    roleFilter: '',
    hospitalFilter: '',
    orderBy: 'lastName',
    typeOrder: 'ASC',
    loading: true,
    searchKeyword: '',
    userDetail: {} as UserData,
    isOpenFormConfirm: false,
    id: '',
    totalPages: 0,
    page: 1,
  })

  const [anchorElementCreateUser, setAnchorElementCreateUser] = useState<null | HTMLElement>(null)

  useEffect(() => {
    if (Object.keys(error).length > 0) {
      handleCheckErrorMessage(error?.message as string)
    }
  }, [error])

  useEffect(() => {
    const listUsersTable = [] as UserRow[]
    listUsers.forEach((item: UserData) => {
      const { avatar, username, email, id, referralCode, vip, coins, createDate } = item
      const userData: UserRow = {
        avatar,
        username,
        email,
        id,
        referralCode,
        coins,
        vip: String(vip),
        createDate
      }
      listUsersTable.push(userData)
    })
    setState((prevState: UserManagementContainerState) => ({
      ...prevState,
      listUsersTable,
      page: page + 1,
      totalPages,
    }))
  }, [listUsers, page, totalPages])

  useEffect(() => {
    dispatch(getListUsers({
      page: 0,
      searchKeyword: state.searchKeyword,
      orderBy: state.orderBy,
      typeOrder: state.typeOrder.toUpperCase()
    }))
    dispatch(getListRequests())
  }, [state.searchKeyword, state.orderBy, state.typeOrder])


  const findLastElementOfString = (value: string, typeSplit = '.'): string => {
    const arrElements: string[] = value.split(typeSplit)
    const index: number = arrElements.length - 1
    return arrElements[index]
  }

  const handleChangeState = (key: string, value: unknown): void => {
    setState((prevState: UserManagementContainerState) => ({
      ...prevState,
      [key]: value as Pick<UserManagementContainerState, keyof UserManagementContainerState>,
    }))
  }

  const handleOpenModalFromUserRow = (id: string) => {
    const userDetail = listUsers.filter(item => { return item.id === id })[0]
    setState((prevState: UserManagementContainerState) => ({
      ...prevState,
      isCreated: false,
      isOpenUpdatedCoinModal: true,
      loading: true,
      userDetail,
    }))
  }

  const handleOrderListUsers = (orderBy: string, typeOrder: string) => {
    setState((prevState: UserManagementContainerState) => ({
      ...prevState,
      orderBy,
      typeOrder,
    }))
  }

  const handleCheckColorText = (value: string): boolean => {
    if (["false"].includes(findLastElementOfString(value ? value.toString() : ''))) return true
    return false
  }

  const handleCheckErrorMessage = (message: string): void => {
    if (message?.includes("401")) {
      removeCurrentUser()
      removeToken()
      dispatch(clearErrorUser())
      toast.error('Expried token!')
      history.push('/login')
    }
  }

  const handleSearch = (value: string) => {
    handleChangeState('page', 1)
    handleChangeState('searchKeyword', value)
  }

  const handleDelete = (): void => {
    handleChangeState('isOpenFormConfirm', false)
    dispatch(deleteUser(state.id))
  }

  const onChangedPage = (value: number): void => {
    dispatch(
      getListUsers(
        {
          page: value - 1,
          searchKeyword: state.searchKeyword,
          orderBy: state.orderBy,
          typeOrder: state.typeOrder.toUpperCase()
        },
      ),
    )
  }

  const handleUpdateEmail = (data: any) => {
    handleChangeState('isOpenedUpdateTemplateModal', false)
    updateEmail({ id: data.id, email: data.email }, callback)
  }

  const callback = (isSuccess: boolean, message?: string): void => {
    if (isSuccess) {
      dispatch(getListUsers({
        page: state.page - 1,
        searchKeyword: state.searchKeyword,
        orderBy: state.orderBy,
        typeOrder: state.typeOrder.toUpperCase()
      }))
      toast.success("Success!")
    } else {
      handleCheckErrorMessage(message as string)
      toast.error(message)
    }
  }

  const handleSubCoin = (id: string, value: number) => {
    handleChangeState('isOpenUpdatedCoinModal', false)
    subCoins({ id, value }, callback)
  }
  const handleAddCoin = (id: string, value: number) => {
    handleChangeState('isOpenUpdatedCoinModal', false)
    addCoins({ id, value }, callback)
  }

  const showLoading: boolean = userManagement.loading

  return (
    <div className="user-management">
      <CustomSearchComponent handleSearch={handleSearch} name='Search with email and user name' />
      <div className="header">
        <h2 className="title">
          Users management
        </h2>
        <Button
          variant="contained"
          className="button justify-content-space-around"
          color="primary"
          onClick={(event: React.MouseEvent<HTMLElement>) => dispatch(getListUsers({ page }))}
        >
          <p className="font-roboto text-white">
            Refresh data
          </p>
        </Button>
      </div>
      <CustomTableContainer
        showLoading={showLoading}
        onClickTableRow={handleOpenModalFromUserRow}
        columns={columns}
        dataTable={state.listUsersTable}
        valueFilters={[state.roleFilter, state.hospitalFilter]}
        // listDataSelectedFilters={state.listDataSelectedFilters}
        isShowPagination
        page={state.page}
        totalPages={state.totalPages}
        handleCheckColorText={handleCheckColorText}
        isHaveAvatar={true}
        onChangedFilter={handleChangeState}
        handleOrderTable={handleOrderListUsers}
        handleShowMoreActions={(anchorElement: HTMLElement, id: string) => {
          const userDetail = userManagement.listUsers.filter(user => user.id === id)[0] || {}
          setAnchorElementCreateUser(anchorElement)
          setState((prevState: UserManagementContainerState) => ({
            ...prevState,
            userDetail,
            id,
          }))
        }}
        isShowMoreActions={true}
        onChangedPage={onChangedPage}
      />
      <CustomMenu
        anchorElement={anchorElementCreateUser}
        vertical={'center'}
        horizontal={'left'}
        listMenuItems={[
          {
            name: 'Update email',
          },
          {
            name: 'Delete',
          },
        ]}
        handleCloseMenu={() => setAnchorElementCreateUser(null)}
        handleSubmit={(name: string) => {
          if (name === 'Delete') {
            handleChangeState('isOpenFormConfirm', true)
          } else {
            handleChangeState('isOpenUpdatedUserModal', true)
          }
          setAnchorElementCreateUser(null)
        }}
      />
      <UpdateCoinModal
        isOpen={state.isOpenUpdatedCoinModal}
        handleCloseModal={() => {
          handleChangeState('userDetail', {})
          handleChangeState('isOpenUpdatedCoinModal', false)
        }}
        handleSubCoin={handleSubCoin}
        handleAddCoin={handleAddCoin}
        isLoading={showLoading}
        userDetail={state.userDetail}
      />
      <UpdateUserModal
        isOpen={state.isOpenUpdatedUserModal}
        handleCloseModal={() => {
          handleChangeState('userDetail', {})
          handleChangeState('isOpenUpdatedUserModal', false)
        }}
        isCreated={false}
        handleSave={handleUpdateEmail}
        isLoading={showLoading}
        userDetail={state.userDetail}
      />
      <ConfirmFormModal
        onCancel={() => handleChangeState('isOpenFormConfirm', false)}
        onYes={handleDelete}
        name={listUsers.filter(item => item.id === state.id)[0]?.username || ''}
        isOpen={state.isOpenFormConfirm}
      />
      <ToastContainer />
    </div>
  )
}